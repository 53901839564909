@font-face {
  font-family: "AvenirLT";
  src:
    local("AvenirLT"),
    url(./AvenirLTStd-Light.otf) format("truetype");
}

@font-face {
  font-family: "AvenirLT-Black";
  src:
    local("AvenirLT-Black"),
    url(./AvenirLTStd-Black.otf) format("truetype");
}

@font-face {
  font-family: "AvenirLT-Medium";
  src:
    local("AvenirLT-Medium"),
    url(./AvenirLTStd-Medium.otf) format("truetype");
}

body {
  margin: 0;
  font-family: AvenirLT;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6f8;
  zoom: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiList-root:hover {
  background-color: "red";
}

.MuiButton-root {
  text-transform: none !important;
}

input[type="password"] {
  margin-top: 6px !important;
}

.two_rows_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 50;
}
