.App {
  text-align: center;
  font-family: AvenirLT !important;
  zoom: reset;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.device-emulator-container {
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@-ms-viewport {
  width: 480;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../icons/downArrow.png") no-repeat;
  width: 10;
  height: 10;
  /* padding-left: 50px; */
  text-transform: lowercase;
  /* display:none; */
  margin-top: 15px;
  cursor: pointer;
}

svg {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiSelect-outlined.MuiSelect-outlined {
  border: 1px solid #c6c6c6;
  padding: 10px;
}

.MuiSelect-filled.MuiSelect-filled {
  padding: 10px;
}

.MuiSelect-filled.MuiSelect-filled:hover {
  border: "none";
}

.row_link {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.hawkeye {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.color_0 {
  background-color: #52d68a;
}

.color_2 {
  background-color: #f1c50e;
}

.color_1 {
  background-color: #e84c3d;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circle.active {
  border: 2px solid #282c34;
}

.react-transform-element1 {
  width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
}

.rotate270 {
  -ms-transform: rotate(270deg);
  /* IE 9 */
  transform: rotate(270deg);
  /* Standard syntax */
}

.wood_vertical {
  background-image: url("../icons/wood_vertical.jpg");
  background-size: contain;
}

.wood_horizontal {
  background-image: url("../icons/wood_horizontal.jpg");
  background-size: contain;
}

#app_logo,
.applogo {
  background-image: url("../icons/logo.png");
  width: 230px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.tail_vertical {
  background-image: url("../icons/tail_horizontal.jpg");
  background-size: contain;
}

.tail_horizontal {
  background-image: url("../icons/tail_horizontal.jpg");
  background-size: contain;
}

.horizontal_lines {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../icons/horizontal_lines.png");
}

.vertical_lines {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../icons/vertical_lines.png");
}

.muicc-colorpicker-button,
.muicc-colorpicker-button div {
  height: 56px !important;
  width: 56px !important;
}

.as::placeholder {
  color: #707272 !important;
  font-size: 24px !important;
  opacity: 1 !important;
  font-weight: 900;
  font-family: AvenirLT-Black;
  /* transform: scaleX(0) !important */
}

input[type="time"] {
  text-transform: lowercase;
}

.previewFrame {
  background-image: url("../icons/previewOverlay.png");
}

.chartjs-render-monitor {
  max-width: 400px !important;
  max-height: 200px !important;
}

.contextIcon:hover {
  background-color: #004c60 !important;
}

.contextMenuIcon:hover {
  background-color: white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chartBoxLabels,
.chartBox,
#floor_builder {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chartBoxLabels::-webkit-scrollbar,
.chartBox::-webkit-scrollbar,
#floor_builder::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar,
::-webkit-scrollbar:horizontal {
  width: 0px;
  height: 0px;
}

.show_scroll::-webkit-scrollbar,
.show_scroll::-webkit-scrollbar:horizontal {
  width: 6px;
  display: block;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb:horizontal {
  box-shadow: inset 0 0 5px #fafafa;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background: #004c60;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #282c34;
}

#tabletFrame {
  background-image: url("../icons/tabletFrame.png");
  background-repeat: no-repeat;
  size: 860;
  width: 860;
  height: 660;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ab1212 !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fff !important;
}

.MuiList-padding {
  padding-bottom: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiRadio-root {
  color: #004c60 !important;
}

.taxMode .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 14px !important;
}

.MuiTab-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline !important;
  text-align: left;
}
